<template>
  <main class="main">
    <nav aria-label="breadcrumb" class="breadcrumb-nav mb-0 border-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">{{ $t("home") }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ $t("brands") }}</li>
          <li class="breadcrumb-item" v-if="$route.query.searchTerm">
            <span>Search - {{ $route.query.searchTerm }}</span>
          </li>
        </ol>
        <h4>{{ $t("brands") }}</h4>
      </div>
    </nav>

    <div class="page-content">
      <div class="container">
        <p v-if="brands.length === 0">No Brands Found</p>
        <div class="row" v-else>
          <div class="col-lg-9 custom_relative">
            <!-- <Pagination :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
              @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" /> -->

            <pagination-comp
              :per-page="perPage"
              :total="totalCount"
              :layout-type="layout"
              :sortBy="sortBy"
              @handleLayout="handleLayout"
              @handlePerPage="handlePerPage"
              @handleSortBy="handleSortBy"
            />

            <p class="no-results" v-if="brands.length === 0">
              Brands Not Available
            </p>
            <div class="cat-blocks-container">
              <template v-if="type !== 'list'">
                <div class="row">
                  <div
                    class="col-6 col-md-4 col-lg-3"
                    v-for="(item, i) in paginatedData"
                    :key="i"
                  >
                    <b-link
                      v-if="item.category != null"
                      :to="`/` + item.category.url_path"
                      class="brand-card"
                    >
                      <figure>
                        <span>
                          <img :src="item.logo" :alt="item.title" />
                        </span>
                      </figure>

                      <h3 class="cat-block-title">{{ item.title }}</h3>
                    </b-link>
                    <b-link
                      class="cat-block"
                      :to="`/brand-details/` + item.urlKey"
                      v-else
                    >
                      <figure>
                        <span>
                          <img :src="item.logo" :alt="item.title" />
                        </span>
                      </figure>

                      <h3 class="cat-block-title">{{ item.title }}</h3>
                    </b-link>
                  </div>
                </div>
              </template>

              <template v-if="type === 'list'">
                <div class="row">
                  <div
                    class="col-12"
                    v-for="(item, i) in paginatedData"
                    :key="i"
                  >
                    <b-link
                      v-if="item.category != null"
                      :to="`/` + item.category.url_path"
                      class="brand-card"
                    >
                      <figure>
                        <span>
                          <img :src="item.logo" :alt="item.title" />
                        </span>
                        <h3 class="cat-block-title">{{ item.title }}</h3>
                      </figure>
                      <button class="btn btn-dark">
                        {{ $t("view_brand") }}
                      </button>
                    </b-link>
                    <div class="cat-block-row" v-else>
                      <figure>
                        <span>
                          <img :src="item.logo" :alt="item.title" />
                        </span>
                        <h3 class="cat-block-title">{{ item.title }}</h3>
                      </figure>
                      <button class="btn btn-dark">
                        {{ $t("view_brand") }}
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 
            <Pagination :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
              @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" /> -->
            <pagination-comp
              :per-page="perPage"
              :total="totalCount"
              :layout-type="layout"
              :sortBy="sortBy"
              @handleLayout="handleLayout"
              @handlePerPage="handlePerPage"
              @handleSortBy="handleSortBy"
            />
          </div>

          <!-- sticky-container -->
          <aside class="col-lg-3 order-lg-first">
            <!-- v-sticky="!isSidebar" sticky-offset="{ top: 69 }" -->
            <div v-if="aggregations && aggregations.length > 0">
              <button
                class="sidebar-fixed-toggler"
                @click="toggleSidebar"
                v-if="isSidebar"
              >
                <i class="icon-cog"></i>
              </button>

              <div class="sidebar-filter-overlay" @click="hideSidebar"></div>
              <shop-sidebar-one
                :is-sidebar="isSidebar"
                :aggregation="this.aggregations"
              ></shop-sidebar-one>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
// import Sticky from 'vue-sticky-directive';
import ShopSidebarOne from "@/esf_weert_mobilesupplies/core/components/partial/shop/sidebar/ShopSidebarOne";
// import Pagination from "@/esf_weert_mobilesupplies/core/components/elements/ProductPagination";
import PaginationComp from "@/esf_weert_mobilesupplies/core/components/elements/PaginationComp";

// import Repository, { baseUrl } from '@/repositories/repository.js';
// import { scrollToPageContent } from '@/utilities/common';

export default {
  components: {
    ShopSidebarOne,
    PaginationComp,
  },
  data: function () {
    return {
      isSidebar: true,
      // pagination
      perPage: 24,
      layout: "cols",
      totalCount: 0,
      sortBy: "price_low_high",
      // pagination
    };
  },
  watch: {
    brands(newBrands) {
      this.totalCount = newBrands.length;
    },
  },
  computed: {
    ...mapGetters({
      aggregations: "product/getAggregations",
    }),
    brands() {
      let b = this.$store.getters["productSerie/getBrands"];
      let bb = [];
      b.forEach((e) => {
        e.items.forEach((item) => {
          bb.push(item);
        });
      });
      return bb;
    },
    // pagination
    type: function () {
      return this.layout;
    },
    currentPage: function () {
      return parseInt(this.$route.query.page ? this.$route.query.page : 1);
    },
    paginatedData() {
      if (!this.brands) return [];

      let start = (this.currentPage - 1) * this.perPage;
      let end = start + this.perPage;

      // Apply sorting based on sortBy
      let sortedCategories = [...this.brands];
      if (this.sortBy === "price_low_high") {
        sortedCategories.sort((a, b) => a.price - b.price);
      } else if (this.sortBy === "price_high_low") {
        sortedCategories.sort((a, b) => b.price - a.price);
      }
      // Add more sorting logic if needed

      return sortedCategories.slice(start, end);
    },
    // pagination
    // getCmsData() {
    //     return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("merken")
    //         ?.content;
    // },
  },
  // watch: {
  //     $route: function () {
  //         this.getBrands(true);
  //     }
  // },
  // created: function () {
  //     this.getBrands();
  // },
  mounted: function () {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });
    this.totalCount = this.brands.length;
  },
  unmounted: function () {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    // getBrands: async function (samePage = false) {
    //     console.log(samePage);
    //     this.type = this.layout;
    //     if (this.type == 'list') {
    //         this.pageTitle = this.$t('brands');
    //     } else if (this.type == 'cols') {
    //         this.pageTitle = this.$t('brands');
    //     }
    // },
    // pagination
    handleLayout: function (layout) {
      this.layout = layout;
    },
    handlePerPage: function (count) {
      this.perPage = count;
    },
    handleSortBy: function (sort) {
      this.sortBy = sort;
    },
    // pagination
    toggleSidebar: function () {
      if (
        document
          .querySelector("body")
          .classList.contains("sidebar-filter-active")
      ) {
        document
          .querySelector("body")
          .classList.remove("sidebar-filter-active");
      } else {
        document.querySelector("body").classList.add("sidebar-filter-active");
      }
    },

    hideSidebar: function () {
      document.querySelector("body").classList.remove("sidebar-filter-active");
    },
    resizeHandler: function () {
      if (window.innerWidth > 991) this.isSidebar = false;
      else this.isSidebar = true;
    },
  },
};
</script>
